import React, { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import LoadingScreen from './components/LoadingScreen';
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";
import MainLayout from "./components/MainLayout";
import BackofficeGuard from "./components/guards/BackofficeGuard";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/guard/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/guard/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/guard/ServerError')));

// Other pages
const Home = Loadable(lazy(() => import('./pages/Home')));
const EdificiAmministrati = Loadable(lazy(() => import('./pages/EdificiAmministrati')));
const MyCommunities = Loadable(lazy(() => import('./pages/MyCommunities')));
const TerminiCondizioni = Loadable(lazy(() => import('./pages/TerminiCondizioni')));
const TerminiCondizioniPagamentoEdificiAmministratore = Loadable(lazy(() => import('./pages/TerminiCondizioniPagamentoEdificiAmministratore')));

const Privacy = Loadable(lazy(() => import('./pages/Privacy')));

//AUTH
const AuthUrl = Loadable(lazy(() => import('./pages/authentication/AuthUrl')));

const OwnerOnBoarding = Loadable(lazy(() => import('./pages/onboarding/OwnerOnBoarding')));
const BuildingOnBoarding = Loadable(lazy(() => import('./pages/onboarding/BuildingOnBoarding')));
const ContractorOnBoarding = Loadable(lazy(() => import('./pages/onboarding/ContractorOnBoarding')));

//New community
const NewCommunityStart = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityStart')));
const NewCommunityScegliEdifici = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityScegliEdifici')));
const NewCommunityCompilaDati = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityCompilaDati')));
const NewCommunityCoinvolgi = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityCoinvolgi')));
const NewCommunityEsaminaRisultati = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityEsaminaRisultati')));
const NewCommunityDefinisciConfigurazione = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityDefinisciConfigurazione')));
const NewCommunityDeliberaInAssemblea = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityDeliberaInAssemblea')));

//UPLOAD
const NewCommunityDocumentiProgettista = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityDocumentiProgettista')));
const NewCommunityDocumentiInstallatore = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityDocumentiInstallatore')));
const NewCommunityDocumentiMembri = Loadable(lazy(() => import('./pages/newcommunity/NewCommunityDocumentiMembri')));

const SurveyStart = Loadable(lazy(() => import('./pages/survey/SurveyStart')));
const SurveyDoing = Loadable(lazy(() => import('./pages/survey/SurveyDoing')));

//BO
const BOBuildingStatusPayment = Loadable(lazy(() => import('./pages/backoffice/BOBuildingStatusPayment')));
const BOGestioneCommunities = Loadable(lazy(() => import('./pages/backoffice/BOGestioneCommunities')));
const BOGestioneDetailCommunity = Loadable(lazy(() => import('./pages/backoffice/gestionecomunita/BOGestioneDetailCommunity')));

const BOGestioneCommunitiesList = Loadable(lazy(() => import('./pages/backoffice/gestionecomunita/BOGestioneCommunitiesList')));
const BOGestioneCommunitiesUnderDevList = Loadable(lazy(() => import('./pages/backoffice/gestionecomunita/BOGestioneCommunitiesUnderDevList')));

const BOCommunityCoperture = Loadable(lazy(() => import('./pages/backoffice/coperture/BOCommunityCoperture')));
const BOCopertura = Loadable(lazy(() => import('./pages/backoffice/copertura/BOCopertura')));

const BOImportBulk = Loadable(lazy(() => import('./pages/backoffice/BOImportBulk')));

//MANUTENTORE:
const ProfileInfo = Loadable(lazy(() => import('./pages/Profile')));
/*
 {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
 */

//EVENTUALE REGISTER
const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: 'faq',
        element: <FAQ />
      },
      {
        path: 'tos',
        element: <TerminiCondizioni />
      },
      {
        path: 'tosamm',
        element: <TerminiCondizioniPagamentoEdificiAmministratore />
      },
      {
        path: 'privacy',
        element: <Privacy />
      },
      {
        path: 'auth',
        element: <GuestGuard><AuthUrl /></GuestGuard>
      },
      {
        path: 'mybuildings',
        element: <AuthGuard><EdificiAmministrati/></AuthGuard>
      },
      {
        path: 'profile',
        element: <AuthGuard><ProfileInfo /></AuthGuard>
      },
      {
        path: 'mycommunities',
        element: <AuthGuard><MyCommunities/></AuthGuard>
      },
      {
        path: 'onboarding/owner',
        element: <OwnerOnBoarding />
      },
      {
        path: 'onboarding/contractor',
        element: <ContractorOnBoarding />
      },
      {
        path: 'onboarding/building',
        element: <BuildingOnBoarding />
      },
      {
        path: 'survey',
        children: [
          {
            path: '/start/:base64IdCommunity',
            element: <SurveyStart />
          },
          {
            path: '/doing',
            element: <SurveyDoing />
          }
        ]
      },
      {
        path: 'bo',
        children: [
          {
            path: '/buildings',
            element: <BackofficeGuard><BOBuildingStatusPayment /></BackofficeGuard>
          },
          {
            path: '/communities',
            children: [
              {
                path: '/',
                element: <BackofficeGuard><BOGestioneCommunities /></BackofficeGuard>
              },
              {
                path: '/detail',
                element: <BackofficeGuard><BOGestioneDetailCommunity /></BackofficeGuard>
              },
              {
                path: '/underDev',
                element: <BackofficeGuard><BOGestioneCommunitiesUnderDevList /></BackofficeGuard>
              },
              {
                path: '/handled',
                element: <BackofficeGuard><BOGestioneCommunitiesList /></BackofficeGuard>
              }
            ]
          },
          {
            path: '/coperture',
            element: <BackofficeGuard><BOCommunityCoperture /></BackofficeGuard>
          },
          {
            path: '/copertura',
            element: <BackofficeGuard><BOCopertura /></BackofficeGuard>
          },
          {
            path: '/importBulk',
            element: <BackofficeGuard><BOImportBulk /></BackofficeGuard>
          }
        ]
      },
      {
        path: 'newcommunity',
        children: [
          {
            path:'/',
            element: <AuthGuard><NewCommunityStart /></AuthGuard>
          },
          {
            path: '/scegliEdifici',
            element: <AuthGuard><NewCommunityScegliEdifici /></AuthGuard>
          },
          {
            path: '/compiladati',
            element: <AuthGuard><NewCommunityCompilaDati /></AuthGuard>
          },
          {
            path: '/coinvolgi',
            element: <AuthGuard><NewCommunityCoinvolgi /></AuthGuard>
          },
          {
            path: '/esaminarisultati',
            element: <AuthGuard><NewCommunityEsaminaRisultati /></AuthGuard>
          },
          {
            path: '/definisciConfigurazione',
            element: <AuthGuard><NewCommunityDefinisciConfigurazione /></AuthGuard>
          },
          {
            path: '/deliberaInAssemblea',
            element: <AuthGuard><NewCommunityDeliberaInAssemblea /></AuthGuard>
          },
          {
            path: '/document/upload/progettista/:base64',
            element: <NewCommunityDocumentiProgettista />
          },
          {
            path: '/document/upload/installatore/:base64',
            element: <NewCommunityDocumentiInstallatore />
          },
          {
            path: '/document/upload/membri/:base64',
            element: <NewCommunityDocumentiMembri />
          }
        ]
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];
export default routes;
