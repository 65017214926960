import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as BrowserLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Avatar, Box, Container, Link, Typography } from '@material-ui/core';
import Logo from '../components/Logo';
import MailIcon from '../res/icons/Mail';
import i18n from "i18next";
import ContactForm from "../components/contact/ContactForm";

const Contact: FC = () => {
  useEffect(() => {
  }, []);

  return (
    <>
      <Helmet>
        <title>{i18n.t("contact")} | {i18n.t("appName")}</title>
      </Helmet>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            lg: 'repeat(2, 1fr)',
            xs: 'repeat(1, 1fr)'
          },
          minHeight: '100%'
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            pt: 8
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              pl: {
                lg: 15
              }
            }}
          >
            <Link
              component={BrowserLink}
              to="/"
            >
              <Logo
                sx={{
                  height: 33,
                  width: 42
                }}
              />
            </Link>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                py: 3
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                  mr: 2
                }}
                variant="rounded"
              >
                <MailIcon />
              </Avatar>
              <Typography
                color="textPrimary"
                variant="overline"
              >
                  {i18n.t("contact_us")}
              </Typography>
            </Box>
            <Typography
              color="textPrimary"
              sx={{ fontWeight: 'fontWeightBold' }}
              variant="h1"
            >
                {i18n.t("parla_esperto")}
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ py: 3 }}
              variant="body1"
            >
                {i18n.t("subTitleContact")}
            </Typography>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            pt: 8
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              pr: {
                lg: 15
              }
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ pb: 3 }}
            >
                {i18n.t("completa_il_form_di_seguito")}
            </Typography>
            <Box sx={{}}>
              <ContactForm />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Contact;
