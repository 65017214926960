import type { FC, FormEvent } from 'react';
import {
    Box,
    Button, Checkbox, FormHelperText,
    Grid,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import i18n from "i18next";
import {Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

const ContactForm: FC = (props) => {
    const navigate = useNavigate();
    return (
        <Formik
            initialValues={{
                email: '',
                fullName: '',
                subject: 'richiesta_informazioni',
                azienda: '',
                cellulare: '',
                testo: '',
                submit: null
            }}
            validationSchema={
                Yup
                    .object()
                    .shape({
                        email: Yup
                            .string()
                            .email(i18n.t("valid_email"))
                            .max(255)
                            .required(i18n.t("required_email")),
                        fullName: Yup
                            .string()
                            .max(255)
                            .required(i18n.t("required_name")),
                        subject: Yup
                            .string()
                            .max(255)
                            .required(i18n.t("required_subject")),
                        cellulare: Yup
                            .string()
                            .max(255),
                        azienda: Yup
                            .string()
                            .max(255),
                        testo: Yup
                            .string()
                            .required(i18n.t("required_testo")),

                    })
            }
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }): Promise<void> => {
                try {
                    var testo = ""
                    if(values.fullName){
                        testo += "<br>Nome cognome: "+values.fullName
                    }
                    if(values.azienda){
                        testo += "<br>Azienda: "+values.azienda
                    }
                    if(values.cellulare){
                        testo += "<br>Cellulare: "+values.cellulare
                    }
                    if(values.email){
                        testo += "<br>Email: "+values.email
                    }
                    testo += "<br>Testo: "+values.testo
                  /*  const resp = await GenericController.contactUs("Micromega",values.email,testo,i18n.t(values.subject))
                    if(resp){
                        navigate('/', {});
                    }else{
                        throw new Error(i18n.t("problemi_invio_mail"))
                    }*/
                } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue
              }): JSX.Element => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...props}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Typography
                                color="textPrimary"
                                sx={{ mb: 1 }}
                                variant="subtitle2"
                            >
                                {i18n.t("fullname")}
                            </Typography>
                            <TextField
                                fullWidth
                                name="fullName"
                                variant="outlined"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.fullName}
                                helperText={touched.fullName && errors.fullName}
                                error={Boolean(touched.fullName && errors.fullName)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Typography
                                color="textPrimary"
                                sx={{ mb: 1 }}
                                variant="subtitle2"
                            >
                                {i18n.t("azienda")}
                            </Typography>
                            <TextField
                                fullWidth
                                name="azienda"
                                variant="outlined"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.azienda}
                                helperText={touched.azienda && errors.azienda}
                                error={Boolean(touched.azienda && errors.azienda)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Typography
                                color="textPrimary"
                                sx={{ mb: 1 }}
                                variant="subtitle2"
                            >
                                {i18n.t("email")}
                            </Typography>
                            <TextField
                                fullWidth
                                name="email"
                                type="email"
                                required
                                variant="outlined"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                helperText={touched.email && errors.email}
                                error={Boolean(touched.email && errors.email)}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Typography
                                color="textPrimary"
                                sx={{ mb: 1 }}
                                variant="subtitle2"
                            >
                                {i18n.t("cellulare")}
                            </Typography>
                            <TextField
                                fullWidth
                                name="cellulare"
                                required
                                type="tel"
                                variant="outlined"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cellulare}
                                helperText={touched.cellulare && errors.cellulare}
                                error={Boolean(touched.cellulare && errors.cellulare)}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <Typography
                                color="textPrimary"
                                sx={{ mb: 1 }}
                                variant="subtitle2"
                            >
                                {i18n.t("oggetto")}
                            </Typography>
                            <TextField
                                fullWidth
                                name="subject"
                                sx={{ mt: 2 }}
                                error={Boolean(touched.subject && errors.subject)}
                                helperText={touched.subject && errors.subject}
                                value={values.subject}
                                select
                                SelectProps={{ native: true }}
                                onChange={handleChange}
                                variant="outlined"
                            >
                                <option
                                    key={"richiesta_informazioni"}
                                    value={"richiesta_informazioni"}>
                                    {i18n.t("richiesta_informazioni")}
                                </option>
                                <option
                                    key={"richiesta_amministrativa"}
                                    value={"richiesta_amministrativa"}>
                                    {i18n.t("richiesta_amministrativa")}
                                </option>
                                <option
                                    key={"informazioni_privacy"}
                                    value={"informazioni_privacy"}>
                                    {i18n.t("informazioni_privacy")}
                                </option>
                                <option
                                    key={"segnala_un_problema"}
                                    value={"segnala_un_problema"}>
                                    {i18n.t("segnala_un_problema")}
                                </option>
                            </TextField>

                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                color="textPrimary"
                                sx={{ mb: 1 }}
                                variant="subtitle2"
                            >
                                {i18n.t("messaggio")}
                            </Typography>
                            <TextField
                                fullWidth
                                name="testo"
                                required
                                multiline
                                rows={6}
                                variant="outlined"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.testo}
                                helperText={touched.testo && errors.testo}
                                error={Boolean(touched.testo && errors.testo)}
                            />
                        </Grid>
                    </Grid>
                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 3
                        }}
                    >
                        <Button
                            color="primary"
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            {i18n.t("invia")}
                        </Button>
                    </Box>
                    <Typography
                        color="textSecondary"
                        sx={{ mt: 3 }}
                        variant="body2"
                    >
                        {i18n.t("scrivendoci_tramite_il_form")}
                        {' '}
                        <Link
                            color="textPrimary"
                            href="#"
                            underline="always"
                            variant="subtitle2"
                        >
                            Privacy Policy
                        </Link>
                        {' '}
                        {i18n.t("e")}
                        {' '}
                        <Link
                            color="textPrimary"
                            href="#"
                            underline="always"
                            variant="subtitle2"
                        >
                            Cookie Policy
                        </Link>
                        .
                    </Typography>
                </form>
            )}
        </Formik>
    );

};

export default ContactForm;
