export class APIError extends Error {
    public message: any;
    public name: string;
    public status: number;
    public stack: any;
    public isPublic: boolean;
    public timestamp: string;

    constructor(
        message: any,
        status: number = 500,
        stack: any,
        isPublic: boolean = false
    ) {
        super();
        this.name = this.constructor.name;
        this.message = message;
        this.status = status;
        this.stack = stack;
        this.isPublic = isPublic;
        this.timestamp = new Date().toISOString();
    }
}

// API ERROR
export const APIBadRequestError = new APIError("Bad Request Error", 400, "", true);
export const APIAuthError = new APIError("Authentication Error", 401, "", true);
export const APINotFoundError = new APIError(
    "Resource not found",
    404,
    "",
    true
);
export const APIRoleError = new APIError("Role Error", 403, "", true);
export const APILawUpdate = new APIError("Law update", 450, "", true);
export const APIGenericError = new APIError("Generic Error", 500, "", true);
export const NetworkError = new APIError("Network Error", 9999, "", true);
// MAP
export const MapApiError: Map<number, APIError> = new Map([
    [400, APIBadRequestError],
    [401, APIAuthError],
    [404, APINotFoundError],
    [403, APIRoleError],
    [450, APILawUpdate],
    [500, APIGenericError],
]);

/***WS ERROR*/
// commons errors with API Error
