import type {FC} from 'react';
import React, {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import {Box, Button, Popover, Typography} from '@material-ui/core';
import i18n from "i18next";
import useAuth from "../hooks/useAuth";
import {User} from "../model/types/user";
import {DEFAULT_WEBSITE_ONBOARDING, urlCatastoHome} from "../constants";
import {Role} from "../model/types/profilece";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
        handleClose();
        await logout();
        window.location.href = urlCatastoHome;
    } catch (err) {
      console.error(err);
      let textToast = i18n.t("non_sono_riuscito_logout");
      toast.error(textToast);
    }
  };
  return (
    <>
      <Box
          onClick={handleOpen}
          sx={{cursor: "pointer"}}
          ref={anchorRef}
      >
          <Typography
              align="center"
              color="textPrimary"
              variant="h6"
          >
              {User.displayName(user)}
          </Typography>
          <Typography
              align="center"
              color="textPrimary"
              variant="body1"
          >
              {User.displayRole(user)}
          </Typography>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
        }}
      >
          <Box sx={{ pt: 2, pl: 2 , pr: 2, pb: 2}}>
              {
                  user.role != Role.backoffice ? (
                      <Button
                          color="primary"
                          fullWidth
                          onClick={()=>{
                              navigate("/profile",{state:{}})
                          }}
                          variant="outlined"
                      >
                          {i18n.t("dati_profilo")}
                      </Button>
                  ): null
              }
              <Button
                  color="primary"
                  fullWidth
                  sx={{mt: user.role != Role.backoffice ? 2 : 0}}
                  onClick={handleLogout}
                  variant="outlined"
              >
                  {i18n.t("logout")}
              </Button>

          </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
