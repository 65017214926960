export class ProfileCE{
    userId: number
    portalId: number
    username: string
    email: string
    name: string
    lastname: string
    address: string
    cap: string
    phone: string
    mobilePhone: string
    profileIdSchedaStr: string
    role: Role
    ragioneSociale: string
    fiscalCode: string //prima del 15 marzo 2024 era vatFiscalCode
    vat: string
    city:{
        id: number,
        desc: string
    }
    province:{
        id: number,
        desc: string
    }
    region:{
        id: number,
        desc: string
    }
    referral: string
    birthDate: string
    iban: string
}

export enum Role{
    amministratore = "amministratore",
    manutentore = "manutentore",
    backoffice = "backoffice"
}
